body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./assets/fonts/Poppins-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./assets/fonts/Poppins-SemiBold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('./assets/fonts/Poppins-Medium.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/Avenir.otf');
    font-weight: normal;
    font-style: normal;
}

.mainText {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 15px;
    line-height: 22.5px;
    color: #fff;
}

.pageTitle {
    font-size: 24px;
    font-family: 'Poppins';
    font-weight: 700;
    line-height: 36px;
    color: #263238;
}

.loginBackground {
    background-color: '#000';
    background-image: url('./assets/img/Banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.loginText {
    margin: 0 44px 0 44px;
    color: #fff;
}

.loginTitle {
    font-size: 24px;
    font-family: 'Poppins';
    margin-bottom: 19px;
}

.rdrDateRangePickerWrapper,
.rdrDateRangeWrapper,
.rdrMonths,
.rdrMonth {
    width: 100%!important;
}

.rdrCalendarWrapper {
    font-family: 'OpenSans'!important;
    color: #3E3E3E!important;
    font-weight: 400!important;
}

.rdrStaticRangeSelected {
    background-color: #EEF1FF!important;
    color: #3947A5!important;
}

.rdrDateDisplayItem input {
    font-size: 12px!important;
    font-family: 'OpenSans'!important;
    font-weight: 600!important;
    line-height: 16.34px!important;
    color: #000!important;
}

.rdrDateDisplayItemActive input, .rdrMonthName, .rdrMonthAndYearPickers select {
    font-size: 12px!important;
    font-family: 'OpenSans'!important;
    font-weight: 600!important;
    line-height: 16.34px!important;
    color: #000!important;
}

svg {
    outline: none;
}

.Mui-selected {
    background-color: rgba(96, 132, 217, 0.14)!important;
    color: #6084D9!important
}

.MuiDataGrid-root {
    border: none!important;
}
.MuiButton-root:hover {
    box-shadow: 0px 2px rgba(57, 102, 208, 0.4);
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 5px!important;
}

.MuiOutlinedInput-notchedOutline {
    border: 1px #E8E8E8;
}

.loader {
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

[class^='word-cloud-text'] svg:first-of-type {
    display: none;
}